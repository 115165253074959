var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('Tooltip',{attrs:{"content":'myTooltipTemplate',"anchor-element":'target',"position":'right'},scopedSlots:_vm._u([{key:"myTooltipTemplate",fn:function(ref){
var props = ref.props;
return [_c('a',[_c('strong',[_vm._v(_vm._s(props.title))])])]}}])},[_c('h1',{staticClass:"bold-header-text app-header mb-3"},[_c('span',{attrs:{"title":_vm.tooltipText}},[_vm._v("PCAB Today ")])]),_c('p',[_vm._v(" A monthly newsletter for compounding pharmacies. Every month we take a deep dive into a standard or quality concept and share upcoming educational events. ")])]),_c('b-row',{staticClass:"mb-2"},[_c('b-col',{attrs:{"md":"12"}},[_c('p',[_vm._v(" Read our monthly newsletter to keep up-to-date on PCAB and industry news. Every month we curate the most important news to help you stay ahead of your competition. ")]),_vm._l((_vm.rsclist),function(ref,index){
var year = ref.year;
var resource = ref.resource;
return _c('achc-collapsible-card',{key:("resource_" + index),attrs:{"card-visible":index == 0 ? true : false}},[_c('template',{slot:"header"},[_c('h2',{staticClass:"resource-program-header"},[_vm._v(_vm._s(year))])]),_c('template',{slot:"body"},_vm._l((resource),function(ref){
var dateCreated = ref.dateCreated; if ( dateCreated === void 0 ) dateCreated = '';
var url = ref.url; if ( url === void 0 ) url = '';
var title = ref.title; if ( title === void 0 ) title = '';
var resourceID = ref.resourceID; if ( resourceID === void 0 ) resourceID = null;
return _c('div',{key:resourceID},[_c('h3',{staticClass:"resource-category-header"},[_vm._v(_vm._s(_vm.formattedDate(dateCreated)))]),_c('a',{staticClass:"resource-link",attrs:{"target":"_blank","href":url,"title":title}},[_vm._v(" "+_vm._s(title)+" ")])])}),0)],2)}),(_vm.emptyStateVisible)?_c('div',{staticClass:"resources-empty-state"},[_vm._v("No resources available")]):_vm._e()],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }