<script>
import axios from "axios";
import { WebAPIUrl } from "../constants/config";
import { mapMutations } from "vuex";

export default {
  data() {
    return {
      statusCode: null,
      processing: false,
      resources: [],
    };
  },

  methods: {
    ...mapMutations([
      "setDangerMessage",
      "setAlertMessage",
      "setInfoMessage",
      "appendInfoMessage",
      "appendDangerMessage",
    ]),
    async getResources(categories = "", programs = [], states = []) {
      let categoryIDs = "0",
        programIDs = "0",
        stateIDs = "0";

      if (programs.length) programIDs = programs.map((p) => `${p.programID}`).join(",");
      if (states.length) stateIDs = states.map((p) => `${p.stateID}`).join(",");
      categoryIDs = categories;

      await axios
        .get(
          // We don't need to add an extra & symbol because each program ID ends with one
          `${WebAPIUrl}/api/navigator-resource?categoryIDs=${categoryIDs}&&programIDs=${programIDs}&&stateIDs=${stateIDs}`
        )
        .then((result) => {
          this.resources = result.data;
        })
        .catch((error) => {
          this.setDangerMessage(error);
        });
    },

    async getChangeRequestResources() {
      await axios
        .get(
          // We don't need to add an extra & symbol because each program ID ends with one
          `${WebAPIUrl}/api/navigator-resource/change-request-forms`
        )
        .then((result) => {
          this.resources = result.data;
        })
        .catch((error) => {
          this.setDangerMessage(error);
        });
    },
    async downloadResourceFile(url, fileName) {
      await axios
        .get(`${url}`, { responseType: "blob" })
        .then((result) => {
          var FILE = window.URL.createObjectURL(new Blob([result.data]));
          var docUrl = document.createElement("a");
          docUrl.href = FILE;
          docUrl.style.position = "fixed";
          docUrl.target = "_blank";
          docUrl.download = fileName;
          document.body.appendChild(docUrl);
          docUrl.click();
        })
        .catch((error) => {
          this.setDangerMessage(error);
        });
    },
  },
};
</script>
