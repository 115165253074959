<template>
  <b-card
    class="collapsible-card shadow-sm"
    :class="{ 'collapsible-card-collapsed': !isCardVisible }"
    :style="from === 'application-affiliates' ? 'margin-top: 1rem' : 'margin-top: 2rem'"
  >
    <div
      v-if="from !== 'application-affiliates'"
      ref="CardBubble"
      class="collapsible-card-bubble"
      @click="handleCardBubbleClick()"
    ></div>

    <slot name="header"></slot>
    <span v-if="from === 'application-affiliates'">
      <div class="collapsible-card-body" :style="{ maxHeight: cardMaxHeight }">
        <slot name="body"></slot></div
    ></span>
    <b-collapse v-else v-model="isCardVisible">
      <div class="collapsible-card-body" :style="{ maxHeight: cardMaxHeight }">
        <slot name="body"></slot>
      </div>
    </b-collapse>
  </b-card>
</template>
<script>
export default {
  // cardVisible: used to show/hide the b-collapse element and change +/- bubble in this component
  // cardMaxHeight: used to restrict the height of the card to force it to separate into columns
  // For an example of this, check out getCardMaxHeight in Resources.vue
  props: {
    cardVisible: {
      default: true,
    },
    cardMaxHeight: {
      default: "none",
    },
    from: {
      default: "none",
    },
  },
  data() {
    return {
      isCardVisible: this.cardVisible,
    };
  },
  mounted() {
    if (this.isCardVisible) {
      this.$refs.CardBubble.classList.add("collapsible-card-clicked");
    }
  },
  methods: {
    handleCardBubbleClick() {
      this.isCardVisible = !this.isCardVisible;
      if (this.isCardVisible) {
        this.$refs.CardBubble.classList.add("collapsible-card-clicked");
      } else {
        this.$refs.CardBubble.classList.remove("collapsible-card-clicked");
      }
    },
  },
};
</script>
